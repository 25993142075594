import Web3 from "web3";
export const NetworkContextName = "BSC Network";
export const ACTIVE_NETWORK = 56;

export const LockStakingContracAddress =
  "0x2e74055095f53fb589611Be1e008bf18eF345740";
export const maxPromote = 10;
export const default_RPC_URL = "https://bsc-dataseed1.binance.org";

export const explorerURL = "https://bscscan.com";
export const NetworkDetails = [
  {
    chainId: "0x38",
    chainName: "BSC  Network",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: [default_RPC_URL],
    blockExplorerUrls: [explorerURL],
  },
];

export const getWeb3Obj = async (RPC_URL = default_RPC_URL) => {
  const httpProvider = new Web3.providers.HttpProvider(RPC_URL);
  const web3 = await new Web3(httpProvider);
  return web3;
};

export const getWeb3ContractObject = async (abi, contractAddress, RPC_URL) => {
  const web3 = await getWeb3Obj(RPC_URL);
  const contract = await new web3.eth.Contract(abi, contractAddress);
  return contract;
};
