import React, { createContext, useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import apiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { calculateTimeLeft } from "src/views/auth/forget-password-link/timer";

import { injected } from "src/connectors";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const { activate, account, chainId, library, deactivate } = useWeb3React();
  //   const [userData, setUserBalanceData] = useState({});
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [kycData, setKycData] = useState([]);
  const [endTime, setEndtime] = useState();

  const [timeLeft, setTimeLeft] = useState();

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  let data = {
    userLoggedIn: isLogin,
    userData,
    kycData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    endTime,
    getProfileHandler: () => getProfileHandler(),
    updateUserProfile: (id) => updateUserProfile(id),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },

    connectWallet: (data) => connectToWallet(data),

    dicconectWalletFun: () => {
      disconnectWallte();
    },
  };

  const connectToWallet = () => {
    try {
      activate(injected, undefined, true).catch((error) => {
        if (error) {
          console.log("ERROR", error);
          const errorMSG = error.message; //+ ' Please install Metamask';
          toast.warn(errorMSG);
          // alert(errorMSG);
          activate(injected);
        }
      });
    } catch (error) {
      console.log("ERROR", error);
    }
  };
  const disconnectWallte = async () => {
    deactivate();
  };

  const updateUserProfile = async (id) => {
    try {
      const res = await axios({
        method: "POST",
        url: apiConfig.updateUserProfile,
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        data: {
          UserID: id,
        },
      });
      if (res.data.status === 200) {
        // setKycData(res?.data?.data?.kyc?.document?.reverse()[0]);
        // setUserData(res?.data?.data);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };

  const getProfileHandler = async () => {
    try {
      const res = await axios.get(apiConfig.myAccount, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 200) {
        setKycData(res?.data?.data?.kyc?.document?.reverse()[0]);
        setUserData(res?.data?.data);
        // updateUserProfile(res?.data?.data?.userId);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };
  console.log("userData--", userData);
  useEffect(() => {
    getProfileHandler();
  }, []);

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
