import React, { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Toolbar,
  makeStyles,
  InputBase,
  IconButton,
  Dialog,
  Button,
} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import SwipeableTemporaryDrawer from "./RightDrawer";
import Logo from "src/component/Logo";
import SearchIcon from "@material-ui/icons/Search";

import DialogContent from "@material-ui/core/DialogContent";
import NotificationList from "src/views/pages/notifications/index";
import { FaQuestion } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import Badge from "@material-ui/core/Badge";
import Axios from "axios";
import ApiConfig from "src/config/APICongig";
import { toast } from "react-toastify";
import { AuthContext } from "src/context/Auth";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
  },
  toolbar: {
    height: 70,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  desktopDrawer: {
    position: "absolute",
    right: 80,
    top: 30,
    width: "100%",
    // background: theme.palette.primary.main,
    height: 266,
    [theme.breakpoints.down("sm")]: {
      width: 600,
      right: 0,
    },
    [theme.breakpoints.down("xs")]: {
      width: 300,
      right: 0,
    },
  },
  iconbutton: {
    color: theme.palette.secondary.main,
  },
  search: {
    height: "50px",
    width: "100%",
    maxWidth: "376px",
    color: "#ABABAB",
    borderRadius: "100px",
    display: "flex",
    backgroundColor: "#302F35",
    alignItems: "center",
    justifyContent: "left",
    margin: "0px 5px",
    marginTop: "5px",
    marginLeft: "8px",
    "& input": {
      color: "#fff",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  searchInput: {
    color: "#ABABAB",
    cursor: "text",
    display: "inline-flex",
    position: "relative",
    fontSize: "14px",
    boxSizing: "border-box",
    alignItems: "center",
    fontWeight: 400,
    lineHeight: "1.1976em",
    width: "71%",
  },
  searchIcon: {
    fontSize: "16px",
    paddingLeft: "10px",
    color: "#fff",
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  NotificationListStyle: {
    background: theme.palette.background.Notification,
    color: theme.palette.text.NotificationColor,
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const themeSeeting = useContext(SettingsContext);

  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const auth = useContext(AuthContext);
  const userdata = auth.userData ? auth.userData : "";
  const [notificationList, setNotificationList] = useState([]);
  const [count, setCount] = useState([]);
  const [loader, setLoader] = useState(false);

  // console.log("notificationList====", count.length);
  const [isLoading, setIsLoading] = useState(false);
  const getNotifictionList = async () => {
    setIsLoading(true);
    try {
      const res = await Axios.get(ApiConfig.getNotification, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
          role: "USER",
        },
        params: {
          fkUserId: userdata?.userId,
        },
      });
      // console.log("res", res);
      if (res.data.status === 1618) {
        setCount(res.data.data.filter((data) => !data.isSeen));
        setNotificationList(res.data.data);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err.response.data.responseMessage);
      console.error(err.response);
      setIsLoading(false);
    }
  };

  const readNotificationHandler = async () => {
    try {
      const res = await Axios.get(ApiConfig.readNotification, {
        headers: {
          authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
      });
      if (res.data.status === 1617) {
        setLoader(false);
      }
    } catch (error) {
      console.log("ERROR", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (userdata?.userId) {
      getNotifictionList();
    }
  }, [userdata?.userId]);
  const handleChange = () => {
    setOpen(true);
    readNotificationHandler();
    setCount([]);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <Logo
        style={{
          paddingTop: "0px",
          // paddingLeft: "15px",
          cursor: "pointer",
          width: "50px",
        }}
      />

      <Box flexGrow={1} />
      {/* <Box className={classes.search}>
        <Box className={classes.searchIcon}>
          <SearchIcon style={{ color: "#ABABAB" }} />
        </Box>
        <InputBase className={classes.searchInput} placeholder="Search " />
      </Box> */}
      <IconButton
        className={classes.NotificationListStyle}
        style={{
          marginRight: 5,
        }}
        // onClick={() => setOpen(true)}
        onClick={handleChange}
      >
        <Badge badgeContent={count?.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      {/* do not remove these codes */}
      {/* <Button variant='contained' color='primary' onClick={()=>{changeTheme("LIGHT")}}>Light</Button> */}
      {/* <Button variant='contained' color='primary' onClick={()=>{changeTheme("DARK")}}>Dark</Button> */}
      {/* upto here */}
      {/* <IconButton
        style={{
          marginRight: 10,
          border: "1px solid #302F35",
          backgroundColor: "#302F35",
        }}
        onClick={() => history.push("/faq")}
      >
        <FaQuestion size={18} className={classes.iconbutton} />
      </IconButton> */}
      <SwipeableTemporaryDrawer />

      <Dialog
        classes={{ paper: classes.desktopDrawer }}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <NotificationList
            isLoading={isLoading}
            count={count}
            notificationList={notificationList}
            popUp={open}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
